import React from 'react';

const Button = ({ gray, text, onClick, marginTop, disabled = false }) => {
  return (
    <>
      <button
        disabled={disabled}
        className={`${gray ? 'gray' : ''} custom-button`}
        onClick={onClick}
      >
        {text}
      </button>
      <style jsx>
        {`
          .custom-button {
            margin-top: ${marginTop ? `${marginTop}px` : '0'};
          }
        `}
      </style>
    </>
  );
};

export default Button;
