import axios from 'axios';
import { Service } from 'axios-middleware';

import { showLoading, dismissLoading } from '../utils/loading';
import WebFn, { callAppFunc } from '../utils/jsbridge/index.js';
import { ERROR_CODES, HTTP_METHOD, CODE_TIMEOUT, API_RESPONSE } from './_const';

export default class Axios {
  constructor({
    baseURL = '',
    token = '',
    oca_token = '',
    cookie = '',
    timeout = 0,
    recallOn = false,
    recallTimes = 0,
    headers = {},
    shouldReturnCode = true,
    refresh_token = '',
  }) {
    this._axios = axios.create();
    this._baseURL = baseURL || '';
    this._timeout = timeout ? timeout * 1000 : 0;
    this._token = token || '';
    this._oca_token = oca_token || '';
    this._cookie = cookie || '';
    this._recallOn = recallOn || false;
    this._recallTimes = recallTimes || 0;
    this._headers = headers;
    this._shouldReturnCode = shouldReturnCode;
    this._refresh_token = refresh_token || '';

    this._init();
  }

  _init() {
    this._axios = axios.create({
      baseURL: this._baseURL,
      timeout: this._timeout,
      headers: Object.assign(
        {},
        {
          'Content-Type': 'application/json',
          'OCA-Token': `${this._oca_token}`,
          'qc-Auth': `${this._token}`,
          'CC-Auth': `${this._cookie}`,
        },
        this._headers
      ),
    });

    this._mutex = this.mutex();
  }

  mutex() {
    // 創建一個互斥鎖
    const mutex = {
      isLocked: false,
      queue: [],
      lock() {
        // 如果鎖已經被鎖定，將當前程式碼塊添加到等待佇列中
        if (this.isLocked) {
          return new Promise((resolve) => {
            this.queue.push(resolve);
          });
        } else {
          // 如果鎖未被鎖定，設置鎖定狀態為 true
          this.isLocked = true;
          return Promise.resolve();
        }
      },
      unlock() {
        // 釋放鎖並檢查等待佇列中是否有程式碼塊
        this.isLocked = false;
        if (this.queue.length > 0) {
          // 如果有等待佇列，從佇列中取出程式碼塊並鎖定鎖
          const next = this.queue.shift();
          next();
        }
      },
    };

    return mutex;
  }

  // async _api(apiMethod, url, parameters = {}, options = {}) {
  //   const isOnline = navigator.onLine;

  //   if (!isOnline) {
  //     callAppFunc('appNetCheck', {});
  //     return;
  //   }

  //   const service = new Service(this._axios);
  //   service.register({
  //     onRequest: (config) => {
  //       // console.log('onRequest', config);
  //       return config;
  //     },
  //     onSync: (promise) => {
  //       // console.log('onSync');
  //       return promise;
  //     },
  //     onResponse: (response) => {
  //       // console.log('onResponse');
  //       return response;
  //     },
  //   });

  //   const isGet = apiMethod === HTTP_METHOD.GET;
  //   const isPost = apiMethod === HTTP_METHOD.POST;
  //   const isDelete = apiMethod === HTTP_METHOD.DELETE;
  //   const isPatch = apiMethod === HTTP_METHOD.PATCH;
  //   const isPut = apiMethod === HTTP_METHOD.PUT;
  //   const typeError = { message: 'error: api method invalid' };
  //   if (
  //     apiMethod !== HTTP_METHOD.GET &&
  //     apiMethod !== HTTP_METHOD.POST &&
  //     apiMethod !== HTTP_METHOD.DELETE &&
  //     apiMethod !== HTTP_METHOD.PATCH &&
  //     apiMethod !== HTTP_METHOD.PUT
  //   )
  //     throw typeError;

  //   let response;
  //   let requestTimes = 0;
  //   const requestTimesLimit = this._recallOn ? this._recallTimes : 0;

  //   let callAxios;
  //   let hadCalled;
  //   let isError = false;

  //   while (
  //     ((!response || response.code === CODE_TIMEOUT) &&
  //       requestTimes < requestTimesLimit) ||
  //     (!this._recallOn && !hadCalled)
  //   ) {
  //     callAxios = undefined;
  //     if (isGet) {
  //       callAxios = this._axios.get(url, parameters);
  //     }
  //     if (isPost) {
  //       callAxios = this._axios.post(url, parameters, options);
  //     }
  //     if (isDelete) {
  //       callAxios = this._axios.delete(url, parameters, options);
  //     }
  //     if (isPatch) {
  //       callAxios = this._axios.patch(url, parameters, options);
  //     }
  //     if (isPut) {
  //       callAxios = this._axios.put(url, parameters, options);
  //     }

  //     // showLoading();
  //     response = await callAxios
  //       .then((axiosRespose) => {
  //         if (
  //           !axiosRespose.data ||
  //           (this._shouldReturnCode && !axiosRespose.data.code) ||
  //           ERROR_CODES.includes(axiosRespose.data.code)
  //         )
  //           throw axiosRespose;

  //         isError = false;
  //         return axiosRespose.data;
  //       })
  //       .catch((error) => {
  //         isError = true;
  //         // return;
  //       });

  //     if (response.code === API_RESPONSE.SessionExpired) {
  //       // 遇到440先清空cookie值
  //       this._cookie = null;

  //       // 把要鎖起來的東西寫入_mutex中
  //       this._mutex
  //         .lock()
  //         .then(async () => {
  //           if (this._cookie != null) {
  //             return;
  //           }
  //           this._axios.defaults.headers['qc-Auth'] = `${this._token}`;
  //           // this._axios.defaults.headers['OCA-Token'] = `${this._oca_token}`;
  //           // this._axios.defaults.headers['CC-Auth'] = `${this._cookie}`;
  //           const responseRefresh = await this._axios
  //             // .get(
  //             //   `v1/qc/sfcc/member/refresh-token?refresh_token=${this._refresh_token}`,
  //             //   parameters,
  //             //   options
  //             // )
  //             .get('v1/qc/sfcc/member/refresh-session', parameters, options)
  //             .then((axiosRespose) => {
  //               // this._api(apiMethod, url, parameters, options);
  //               return axiosRespose.data;
  //             })
  //             .catch((error) => {
  //               isError = true;
  //               // return;
  //             });
  //           if (responseRefresh.code === '200') {
  //             // this._token = responseRefresh.data.Authorization;
  //             // this._oca_token = responseRefresh.data.access_token;
  //             this._cookie = responseRefresh.data.cookie;
  //             // this._refresh_token = responseRefresh.data.refresh_token;

  //             // this._axios.defaults.headers['Authorization'] = `${this._token}`;
  //             // this._axios.defaults.headers['OCA-Token'] = `${this._oca_token}`;
  //             this._axios.defaults.headers['CC-Auth'] = `${this._cookie}`;

  //             const dataRefresh = {
  //               // access_token: this._oca_token,
  //               // refresh_token: this._refresh_token,
  //               // Authorization: this._token,
  //               cookie: this._cookie,
  //             };

  //             callAppFunc('updateLoginData', {
  //               data: JSON.stringify(dataRefresh),
  //             });

  //             return;
  //           } else {
  //             // alert(responseRefresh.reason);
  //             callAppFunc('doLogout', {});
  //             return;
  //           }
  //         })
  //         .finally(() => {
  //           this._mutex.unlock();
  //         });

  //       hadCalled = false;
  //       this._recallOn = false;
  //       continue;
  //     }

  //     hadCalled = true;
  //     requestTimes++;
  //     dismissLoading();
  //   }

  //   if (response.code === API_RESPONSE.Unauthorized) {
  //     // alert(response.reason);
  //     callAppFunc('doLogout', {});
  //   }

  //   if (isError) throw response;
  //   return response;
  // }

  // Alvin remarks: 未加互斥鎖
  // async _api(apiMethod, url, parameters = {}, options = {}) {
  //   const isOnline = navigator.onLine;

  //   if (!isOnline) {
  //     callAppFunc('appNetCheck', {});
  //     return;
  //   }

  //   const service = new Service(this._axios);
  //   service.register({
  //     onRequest: (config) => {
  //       // console.log('onRequest', config);
  //       return config;
  //     },
  //     onSync: (promise) => {
  //       // console.log('onSync');
  //       return promise;
  //     },
  //     onResponse: (response) => {
  //       // console.log('onResponse');
  //       return response;
  //     },
  //   });

  //   const isGet = apiMethod === HTTP_METHOD.GET;
  //   const isPost = apiMethod === HTTP_METHOD.POST;
  //   const isDelete = apiMethod === HTTP_METHOD.DELETE;
  //   const isPatch = apiMethod === HTTP_METHOD.PATCH;
  //   const isPut = apiMethod === HTTP_METHOD.PUT;
  //   const typeError = { message: 'error: api method invalid' };
  //   if (
  //     apiMethod !== HTTP_METHOD.GET &&
  //     apiMethod !== HTTP_METHOD.POST &&
  //     apiMethod !== HTTP_METHOD.DELETE &&
  //     apiMethod !== HTTP_METHOD.PATCH &&
  //     apiMethod !== HTTP_METHOD.PUT
  //   )
  //     throw typeError;

  //   let response;
  //   let requestTimes = 0;
  //   const requestTimesLimit = this._recallOn ? this._recallTimes : 0;

  //   let callAxios;
  //   let hadCalled;
  //   let isError = false;

  //   while (
  //     ((!response || response.code === CODE_TIMEOUT) &&
  //       requestTimes < requestTimesLimit) ||
  //     (!this._recallOn && !hadCalled)
  //   ) {
  //     callAxios = undefined;
  //     if (isGet) {
  //       callAxios = this._axios.get(url, parameters);
  //     }
  //     if (isPost) {
  //       callAxios = this._axios.post(url, parameters, options);
  //     }
  //     if (isDelete) {
  //       callAxios = this._axios.delete(url, parameters, options);
  //     }
  //     if (isPatch) {
  //       callAxios = this._axios.patch(url, parameters, options);
  //     }
  //     if (isPut) {
  //       callAxios = this._axios.put(url, parameters, options);
  //     }

  //     // showLoading();
  //     response = await callAxios
  //       .then((axiosRespose) => {
  //         if (
  //           !axiosRespose.data ||
  //           (this._shouldReturnCode && !axiosRespose.data.code) ||
  //           ERROR_CODES.includes(axiosRespose.data.code)
  //         )
  //           throw axiosRespose;

  //         isError = false;
  //         return axiosRespose.data;
  //       })
  //       .catch((error) => {
  //         isError = true;
  //         // return;
  //       });

  //     if (response.code === API_RESPONSE.SessionExpired) {
  //       this._axios.defaults.headers['qc-Auth'] = `${this._token}`;
  //       // this._axios.defaults.headers['OCA-Token'] = `${this._oca_token}`;
  //       // this._axios.defaults.headers['CC-Auth'] = `${this._cookie}`;
  //       const responseRefresh = await this._axios
  //         // .get(
  //         //   `v1/qc/sfcc/member/refresh-token?refresh_token=${this._refresh_token}`,
  //         //   parameters,
  //         //   options
  //         // )
  //         .get('v1/qc/sfcc/member/refresh-session', parameters, options)
  //         .then((axiosRespose) => {
  //           // this._api(apiMethod, url, parameters, options);
  //           return axiosRespose.data;
  //         })
  //         .catch((error) => {
  //           isError = true;
  //           // return;
  //         });

  //       if (responseRefresh.code === '200') {
  //         // this._token = responseRefresh.data.Authorization;
  //         // this._oca_token = responseRefresh.data.access_token;
  //         this._cookie = responseRefresh.data.cookie;
  //         // this._refresh_token = responseRefresh.data.refresh_token;

  //         // this._axios.defaults.headers['Authorization'] = `${this._token}`;
  //         // this._axios.defaults.headers['OCA-Token'] = `${this._oca_token}`;
  //         this._axios.defaults.headers['CC-Auth'] = `${this._cookie}`;

  //         const dataRefresh = {
  //           // access_token: this._oca_token,
  //           // refresh_token: this._refresh_token,
  //           // Authorization: this._token,
  //           cookie: this._cookie,
  //         };

  //         callAppFunc('updateLoginData', {
  //           data: JSON.stringify(dataRefresh),
  //         });

  //         continue;
  //       } else {
  //         // alert(responseRefresh.reason);
  //         callAppFunc('doLogout', {});
  //         return;
  //       }
  //     }

  //     requestTimes++;
  //     dismissLoading();
  //     hadCalled = true;
  //   }

  //   if (response.code === API_RESPONSE.Unauthorized) {
  //     // alert(response.reason);
  //     callAppFunc('doLogout', {});
  //   }

  //   if (isError) throw response;
  //   return response;
  // }

  // Alvin remarks: 變數鎖
  async _api(apiMethod, url, parameters = {}, options = {}) {
    callAppFunc('checkNetworkStatus');

    const service = new Service(this._axios);
    service.register({
      onRequest: (config) => {
        // console.log('onRequest', config);
        return config;
      },
      onSync: (promise) => {
        // console.log('onSync');
        return promise;
      },
      onResponse: (response) => {
        // console.log('onResponse');
        return response;
      },
    });

    const isGet = apiMethod === HTTP_METHOD.GET;
    const isPost = apiMethod === HTTP_METHOD.POST;
    const isDelete = apiMethod === HTTP_METHOD.DELETE;
    const isPatch = apiMethod === HTTP_METHOD.PATCH;
    const isPut = apiMethod === HTTP_METHOD.PUT;
    const typeError = { message: 'error: api method invalid' };
    if (
      apiMethod !== HTTP_METHOD.GET &&
      apiMethod !== HTTP_METHOD.POST &&
      apiMethod !== HTTP_METHOD.DELETE &&
      apiMethod !== HTTP_METHOD.PATCH &&
      apiMethod !== HTTP_METHOD.PUT
    )
      throw typeError;

    let response;
    let requestTimes = 0;
    const requestTimesLimit = this._recallOn ? this._recallTimes : 0;

    let callAxios;
    let hadCalled;
    let isError = false;

    while (
      ((!response || response.code === CODE_TIMEOUT) &&
        requestTimes < requestTimesLimit) ||
      (!this._recallOn && !hadCalled)
    ) {
      callAxios = undefined;
      if (isGet) {
        callAxios = this._axios.get(url, parameters);
      }
      if (isPost) {
        callAxios = this._axios.post(url, parameters, options);
      }
      if (isDelete) {
        callAxios = this._axios.delete(url, parameters, options);
      }
      if (isPatch) {
        callAxios = this._axios.patch(url, parameters, options);
      }
      if (isPut) {
        callAxios = this._axios.put(url, parameters, options);
      }

      // showLoading();
      response = await callAxios
        .then((axiosRespose) => {
          if (
            !axiosRespose.data ||
            (this._shouldReturnCode && !axiosRespose.data.code) ||
            ERROR_CODES.includes(axiosRespose.data.code)
          )
            throw axiosRespose;

          isError = false;
          return axiosRespose.data;
        })
        .catch((error) => {
          isError = true;
          // callAppFunc('appNetCheck', { source: 'web' });
          // return;
        });

      if (response.code === API_RESPONSE.SessionExpired) {
        // if (true) {
        // 遇到440先清空cookie值
        this._cookie = null;

        console.log('在鎖外面');

        this.lockProcess();
        console.log('進入鎖');
        if (this._cookie == null) {
          console.log('更新token');
          this._lockApi = true;
          this._axios.defaults.headers['qc-Auth'] = `${this._token}`;
          // this._axios.defaults.headers['OCA-Token'] = `${this._oca_token}`;
          // this._axios.defaults.headers['CC-Auth'] = `${this._cookie}`;
          const responseRefresh = await this._axios
            // .get(
            //   `v1/qc/sfcc/member/refresh-token?refresh_token=${this._refresh_token}`,
            //   parameters,
            //   options
            // )
            .get('v1/qc-site/Api-refreshSession', parameters, options)
            .then((axiosRespose) => {
              // this._api(apiMethod, url, parameters, options);
              return axiosRespose.data;
            })
            .catch((error) => {
              isError = true;
              // return;
            });

          if (responseRefresh.code === '200') {
            console.log('update token');
            // this._token = responseRefresh.data.Authorization;
            // this._oca_token = responseRefresh.data.access_token;
            this._cookie = responseRefresh.data.cookie;
            // this._refresh_token = responseRefresh.data.refresh_token;

            // this._axios.defaults.headers['Authorization'] = `${this._token}`;
            // this._axios.defaults.headers['OCA-Token'] = `${this._oca_token}`;
            this._axios.defaults.headers['CC-Auth'] = `${this._cookie}`;

            const dataRefresh = {
              // access_token: this._oca_token,
              // refresh_token: this._refresh_token,
              // Authorization: this._token,
              cookie: this._cookie,
            };

            callAppFunc('updateLoginData', {
              data: JSON.stringify(dataRefresh),
            });

            this._lockApi = false;
            console.log('重打api');
            continue;
          } else {
            // alert(responseRefresh.reason);
            callAppFunc('doLogout', {});
            this._lockApi = false;
            return;
          }
        }
      }

      requestTimes++;
      // dismissLoading();
      hadCalled = true;
    }

    if (response.code === API_RESPONSE.Unauthorized) {
      // alert(response.reason);
      callAppFunc('doLogout', {});
    }

    if (isError) throw response;
    return response;
  }

  lockProcess() {
    setTimeout(() => {
      if (this._lockApi) {
        setTimeout(() => {
          this.lockProcess();
        }, 200);
      }
    }, 10);
  }

  /**
   * Get data from server
   * @param url target path
   * @param params request params
   */
  get(url, parameters, options) {
    options = {
      ...options,
      params: parameters,
    };
    return this._api(HTTP_METHOD.GET, url, options);
  }

  /**
   * Post data to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  post(url, parameters, options) {
    return this._api(HTTP_METHOD.POST, url, parameters, options);
  }

  /**
   * Delete date to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  delete(url, parameters, options) {
    return this._api(HTTP_METHOD.DELETE, url, parameters, options);
  }

  /**
   * Patch data to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  patch(url, parameters, options) {
    return this._api(HTTP_METHOD.PATCH, url, parameters, options);
  }

  /**
   * Put data to server
   * @param url target path
   * @param params request params
   * @param recallOn enable recall (default: true)
   * @param recallTimes times of recall (default: 3)
   */
  put(url, parameters, options) {
    return this._api(HTTP_METHOD.PUT, url, parameters, options);
  }

  /**
   * Update the token of API instance
   * @param token the new token to update
   */
  updateToken(token, accessToken, cookie, refreshToken) {
    this._token = token;
    this._cookie = cookie;
    this._refresh_token = refreshToken;
    this._oca_token = accessToken;

    // update token
    this._axios.defaults.headers['qc-Auth'] = `${token}`;
    this._axios.defaults.headers['OCA-Token'] = `${accessToken}`;
    this._axios.defaults.headers['CC-Auth'] = `${cookie}`;
  }
}
