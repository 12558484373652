import React, { useContext, createContext, useState } from 'react';
import PropTypes from 'prop-types';
import useProvideMount from './_useProvideMount';
import { useSelector } from 'react-redux';
import { getters } from '../../redux/store';

const mountContext = createContext();

export const ProvideMount = ({ children }) => {
  const mount = useProvideMount();
  const isReady = useSelector((state) => getters.isReady(state));

  return isReady ? (
    <mountContext.Provider value={mount}>{children}</mountContext.Provider>
  ) : (
    <div></div>
  );
};

ProvideMount.propTypes = {
  children: PropTypes.node,
};
