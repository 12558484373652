import { createSlice } from '@reduxjs/toolkit';

export const smartRecipe = createSlice({
  name: 'smartRecipe',
  initialState: {
    totalRecipes: 0,
    welcomeMsg: '',
    optionList: [],
    prevPath: [],
    dialogListData: [],
    recipeList: undefined,
    greeting: '',
    memberProfile: undefined,
    dialogDefault: {
      mode: 'NORMAL',
      food: '',
      type: '',
      recipeListData: {
        total: 0,
        page: 1,
        per_page: 10,
        rows: undefined,
      },
      recipeActiveIndexList: [0],
    },
    dialogAIDefault: {
      mode: 'AI',
      question: '',
      answer: '',
      recipeListData: undefined,
      showRecipeRow: 1,
      recipeActiveIndexList: [0],
    },
    shouldGoToBottom: false,
    recipeFromWhichMode: '',
  },
  reducers: {
    setPageInfo: (state, action) => {
      state.totalRecipes = action.payload.total_recipes;
      state.welcomeMsg = action.payload.welcome_msg;
      state.optionList = action.payload.info;
    },
    setPrevPath: (state, action) => {
      state.prevPath = action.payload;
    },
    setDialogListData: (state, action) => {
      state.dialogListData = action.payload;
    },
    setRecipeList: (state, action) => {
      state.recipeList = action.payload;
    },
    setGreeting: (state, action) => {
      state.greeting = action.payload;
    },
    setMemberProfile: (state, action) => {
      state.memberProfile = action.payload;
    },
    setShouldGoToBottom: (state, action) => {
      state.shouldGoToBottom = action.payload;
    },
    setRecipeFromWhichMode: (state, action) => {
      state.recipeFromWhichMode = action.payload;
    },
  },
});

export const {
  setPageInfo,
  setPrevPath,
  setDialogListData,
  setRecipeList,
  setGreeting,
  setMemberProfile,
  setShouldGoToBottom,
  setRecipeFromWhichMode,
} = smartRecipe.actions;

export const getPageInfo = (state) => {
  return {
    totalRecipes: state.smartRecipe.totalRecipes,
    welcomeMsg: state.smartRecipe.welcomeMsg,
    optionList: state.smartRecipe.optionList,
  };
};

export const getPrevPath = (state) => {
  return state.smartRecipe.prevPath;
};

export const getDialogListData = (state) => {
  return state.smartRecipe.dialogListData;
};

export const getOptionList = (state) => {
  return state.smartRecipe.optionList;
};

export const getRecipeList = (state) => {
  return state.smartRecipe.recipeList;
};

export const getGreeting = (state) => {
  return state.smartRecipe.greeting;
};

export const getMemberProfile = (state) => {
  return state.smartRecipe.memberProfile;
};

export const getDialogDefault = (state) => {
  return state.smartRecipe.dialogDefault;
};

export const getDialogAIDefault = (state) => {
  return state.smartRecipe.dialogAIDefault;
};

export const getShouldGoToBottom = (state) => {
  return state.smartRecipe.shouldGoToBottom;
};

export const getRecipeFromWhichMode = (state) => {
  return state.smartRecipe.recipeFromWhichMode;
};

export default smartRecipe.reducer;
