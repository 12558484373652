import React, { useEffect } from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  display: none;
  width: 100%;
  height: 100%;
  z-index: -10;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #00000040;
`;

const Dialog = styled.div`
  opacity: 0;
  position: fixed;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 75vw;
  min-width: 250px;
  background: #ffffff;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
  padding: 20px;
  z-index: -10;
  transition: top 250ms, opacity 250ms, z-index 200ms ease-in;
  max-height: calc(100vh / 2);
`;

const Container = styled.div`
  position: fixed;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.show {
    z-index: 1000;

    ${Overlay} {
      display: block;
      z-index: 1000;
    }

    ${Dialog} {
      opacity: 1;
      top: 50%;
      z-index: 1010;
      transition: top 250ms, opacity 250ms, z-index 200ms ease-in;
    }
  }
`;

const DialogBase = ({ children, show = false }) => {
  useEffect(() => {
    if (show) {
      document.querySelector('body').style.overflow = 'hidden';
    } else {
      document.querySelector('body').style.overflow = '';
    }
  }, [show]);

  return (
    <Container className={`${show ? 'show' : ''}`}>
      <Overlay />
      <Dialog>{children}</Dialog>
    </Container>
  );
};

export default DialogBase;
