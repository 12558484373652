import React from 'react';

import IconSuccess from '../../images/icons/icon-success/icon-success.png';
import AButton from '../a_button';
import Button from '../button';
import DialogBase from './_alert_base';

const LinkAlert = ({
  show,
  showIcon,
  title,
  icon,
  txtLeft,
  txtRight,
  onLeft,
  onRight,
  linkUrl,
}) => {
  return (
    <>
      <DialogBase show={show}>
        <div className="alert-title">{title || ''}</div>
        {showIcon && <img className="alert-icon" src={icon || IconSuccess} />}
        <div className="alert-buttons">
          {txtLeft !== undefined && txtLeft && (
            <Button gray onClick={onLeft} text={txtLeft || '取消'} />
          )}
          {txtRight !== undefined && txtRight && (
            <AButton
              link={linkUrl}
              onClick={onRight}
              text={txtRight || '確定'}
            />
          )}
        </div>
      </DialogBase>

      <style jsx>{`
        .alert-title {
          font-family: Noto Sans TC;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 35px;
          color: #3b3516;
          margin-bottom: 14px;
          white-space: break-spaces;
        }

        .alert-icon {
          margin-bottom: 12px;
        }

        .alert-buttons {
          display: flex;
          // grid-template-columns: 1fr 1fr;
          column-gap: 12px;
        }
      `}</style>
    </>
  );
};

export default LinkAlert;
