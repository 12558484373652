exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-addition-index-jsx": () => import("./../../../src/pages/addition/index.jsx" /* webpackChunkName: "component---src-pages-addition-index-jsx" */),
  "component---src-pages-cart-index-jsx": () => import("./../../../src/pages/cart/index.jsx" /* webpackChunkName: "component---src-pages-cart-index-jsx" */),
  "component---src-pages-cart-modal-coupon-jsx": () => import("./../../../src/pages/cart/ModalCoupon.jsx" /* webpackChunkName: "component---src-pages-cart-modal-coupon-jsx" */),
  "component---src-pages-cart-payment-option-jsx": () => import("./../../../src/pages/cart/payment-option.jsx" /* webpackChunkName: "component---src-pages-cart-payment-option-jsx" */),
  "component---src-pages-cart-promo-code-jsx": () => import("./../../../src/pages/cart/promo-code.jsx" /* webpackChunkName: "component---src-pages-cart-promo-code-jsx" */),
  "component---src-pages-category-list-jsx": () => import("./../../../src/pages/category/list.jsx" /* webpackChunkName: "component---src-pages-category-list-jsx" */),
  "component---src-pages-category-product-category-id-jsx": () => import("./../../../src/pages/category/product/[CategoryId].jsx" /* webpackChunkName: "component---src-pages-category-product-category-id-jsx" */),
  "component---src-pages-coupon-index-jsx": () => import("./../../../src/pages/coupon/index.jsx" /* webpackChunkName: "component---src-pages-coupon-index-jsx" */),
  "component---src-pages-coupon-modal-coupon-rule-jsx": () => import("./../../../src/pages/coupon/ModalCouponRule.jsx" /* webpackChunkName: "component---src-pages-coupon-modal-coupon-rule-jsx" */),
  "component---src-pages-dispatch-setting-jsx": () => import("./../../../src/pages/dispatch/setting.jsx" /* webpackChunkName: "component---src-pages-dispatch-setting-jsx" */),
  "component---src-pages-dispatch-tracking-trans-no-jsx": () => import("./../../../src/pages/dispatch/tracking/[transNo].jsx" /* webpackChunkName: "component---src-pages-dispatch-tracking-trans-no-jsx" */),
  "component---src-pages-dispatch-v-2-location-radio-jsx": () => import("./../../../src/pages/dispatchV2/LocationRadio.jsx" /* webpackChunkName: "component---src-pages-dispatch-v-2-location-radio-jsx" */),
  "component---src-pages-dispatch-v-2-setting-jsx": () => import("./../../../src/pages/dispatchV2/setting.jsx" /* webpackChunkName: "component---src-pages-dispatch-v-2-setting-jsx" */),
  "component---src-pages-dispatch-v-2-tracking-trans-no-jsx": () => import("./../../../src/pages/dispatchV2/tracking/[transNo].jsx" /* webpackChunkName: "component---src-pages-dispatch-v-2-tracking-trans-no-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-member-delete-jsx": () => import("./../../../src/pages/member/delete.jsx" /* webpackChunkName: "component---src-pages-member-delete-jsx" */),
  "component---src-pages-member-index-jsx": () => import("./../../../src/pages/member/index.jsx" /* webpackChunkName: "component---src-pages-member-index-jsx" */),
  "component---src-pages-member-receiver-[mode]-jsx": () => import("./../../../src/pages/member/receiver/[mode].jsx" /* webpackChunkName: "component---src-pages-member-receiver-[mode]-jsx" */),
  "component---src-pages-member-receiver-list-jsx": () => import("./../../../src/pages/member/receiver/list.jsx" /* webpackChunkName: "component---src-pages-member-receiver-list-jsx" */),
  "component---src-pages-member-receiver-v-2-[mode]-jsx": () => import("./../../../src/pages/member/receiverV2/[mode].jsx" /* webpackChunkName: "component---src-pages-member-receiver-v-2-[mode]-jsx" */),
  "component---src-pages-member-receiver-v-2-list-jsx": () => import("./../../../src/pages/member/receiverV2/list.jsx" /* webpackChunkName: "component---src-pages-member-receiver-v-2-list-jsx" */),
  "component---src-pages-member-tax-[mode]-jsx": () => import("./../../../src/pages/member/tax/[mode].jsx" /* webpackChunkName: "component---src-pages-member-tax-[mode]-jsx" */),
  "component---src-pages-member-tax-list-jsx": () => import("./../../../src/pages/member/tax/list.jsx" /* webpackChunkName: "component---src-pages-member-tax-list-jsx" */),
  "component---src-pages-member-validinfo-jsx": () => import("./../../../src/pages/member/validinfo.jsx" /* webpackChunkName: "component---src-pages-member-validinfo-jsx" */),
  "component---src-pages-message-payment-fail-trans-no-jsx": () => import("./../../../src/pages/message/payment-fail/[transNo].jsx" /* webpackChunkName: "component---src-pages-message-payment-fail-trans-no-jsx" */),
  "component---src-pages-mycoupon-index-jsx": () => import("./../../../src/pages/mycoupon/index.jsx" /* webpackChunkName: "component---src-pages-mycoupon-index-jsx" */),
  "component---src-pages-mycoupon-modal-detail-jsx": () => import("./../../../src/pages/mycoupon/ModalDetail.jsx" /* webpackChunkName: "component---src-pages-mycoupon-modal-detail-jsx" */),
  "component---src-pages-mycoupon-modal-usage-jsx": () => import("./../../../src/pages/mycoupon/ModalUsage.jsx" /* webpackChunkName: "component---src-pages-mycoupon-modal-usage-jsx" */),
  "component---src-pages-order-[info]-info-jsx": () => import("./../../../src/pages/order/info/[info].jsx" /* webpackChunkName: "component---src-pages-order-[info]-info-jsx" */),
  "component---src-pages-order-detail-trans-no-jsx": () => import("./../../../src/pages/order/detail/[transNo].jsx" /* webpackChunkName: "component---src-pages-order-detail-trans-no-jsx" */),
  "component---src-pages-order-help-jsx": () => import("./../../../src/pages/order/help.jsx" /* webpackChunkName: "component---src-pages-order-help-jsx" */),
  "component---src-pages-order-list-jsx": () => import("./../../../src/pages/order/list.jsx" /* webpackChunkName: "component---src-pages-order-list-jsx" */),
  "component---src-pages-payment-redirect-jsx": () => import("./../../../src/pages/payment/redirect.jsx" /* webpackChunkName: "component---src-pages-payment-redirect-jsx" */),
  "component---src-pages-payment-result-jsx": () => import("./../../../src/pages/payment/result.jsx" /* webpackChunkName: "component---src-pages-payment-result-jsx" */),
  "component---src-pages-product-product-id-jsx": () => import("./../../../src/pages/product/[ProductId].jsx" /* webpackChunkName: "component---src-pages-product-product-id-jsx" */),
  "component---src-pages-recommend-index-jsx": () => import("./../../../src/pages/recommend/index.jsx" /* webpackChunkName: "component---src-pages-recommend-index-jsx" */),
  "component---src-pages-refound-detail-refund-code-jsx": () => import("./../../../src/pages/refound/detail/[RefundCode].jsx" /* webpackChunkName: "component---src-pages-refound-detail-refund-code-jsx" */),
  "component---src-pages-refound-list-jsx": () => import("./../../../src/pages/refound/list.jsx" /* webpackChunkName: "component---src-pages-refound-list-jsx" */),
  "component---src-pages-sample-2-jsx": () => import("./../../../src/pages/sample2.jsx" /* webpackChunkName: "component---src-pages-sample-2-jsx" */),
  "component---src-pages-sample-jsx": () => import("./../../../src/pages/sample.jsx" /* webpackChunkName: "component---src-pages-sample-jsx" */),
  "component---src-pages-search-index-jsx": () => import("./../../../src/pages/search/index.jsx" /* webpackChunkName: "component---src-pages-search-index-jsx" */),
  "component---src-pages-search-result-[keyword]-jsx": () => import("./../../../src/pages/search/result/[keyword].jsx" /* webpackChunkName: "component---src-pages-search-result-[keyword]-jsx" */),
  "component---src-pages-smart-recipe-index-jsx": () => import("./../../../src/pages/smart-recipe/index.jsx" /* webpackChunkName: "component---src-pages-smart-recipe-index-jsx" */),
  "component---src-pages-smart-recipe-info-recipe-idx-jsx": () => import("./../../../src/pages/smart-recipe/info/[recipeIdx].jsx" /* webpackChunkName: "component---src-pages-smart-recipe-info-recipe-idx-jsx" */),
  "component---src-pages-smart-recipe-my-recipe-jsx": () => import("./../../../src/pages/smart-recipe/my-recipe.jsx" /* webpackChunkName: "component---src-pages-smart-recipe-my-recipe-jsx" */)
}

