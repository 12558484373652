import { Axios, useAPIMethods } from '../../api';

const BASIC_API_URL = process.env.GATSBY_BASE_API_URL;
// const BASIC_PROXY_URL = process.env.GATSBY_BASE_PROXY_URL;

const useProvideAPI = () => {
  // const DEBUG_ENABLED = process.env.GATSBY_USE_DEBUG === 'true';
  const url = BASIC_API_URL;
  // if (DEBUG_ENABLED) {
  //   url = BASIC_PROXY_URL + BASIC_API_URL;
  // }

  const axios = new Axios({
    baseURL: url,
    timeout: 60,
    recallOn: false,
    recallTimes: 3,
    shouldReturnCode: true,
  });

  const api = useAPIMethods({ axios });

  return Object.assign({}, api);
};

export default useProvideAPI;
