import { callAppFunc } from './jsbridge/index';

const IN_APP = process.env.GATSBY_INAPP === 'true';

let isShowing = false;

const showLoading = (immediate = false) => {
  if (IN_APP && !isShowing) {
    isShowing = true;
    callAppFunc('showLoading', { immediate: immediate ? 'true' : 'false' });
  }
};

const dismissLoading = (immediate = false) => {
  console.log('INAPP', IN_APP);
  if (IN_APP) {
    callAppFunc('dismissLoading', { immediate: immediate ? 'true' : 'false' });
  }

  isShowing = false;
};

export { showLoading, dismissLoading };
