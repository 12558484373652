import React from 'react';

const AButton = ({ gray, text, marginTop, link, onClick }) => {
  return (
    <>
      <a
        className={`${gray ? 'gray' : ''} custom-abutton`}
        href={link}
        onClick={onClick}
      >
        {text}
      </a>
      <style jsx>
        {`
          .custom-abutton {
            background: #5fd2da;
            border: 1px solid #5fd2da;
            border-radius: 40px;
            font-weight: bold;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #ffffff;
            padding: 17px 0 14px;
            width: 100%;
            margin-top: ${marginTop ? `${marginTop}px` : '0'};
            text-decoration: none;
            &:hover {
              text-decoration: none;
            }
          }
        `}
      </style>
    </>
  );
};

export default AButton;
