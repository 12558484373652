import React, { useState, useEffect } from 'react';
import { useInput } from 'rooks';

const InputTextField = ({
  label,
  message,
  htmlFor,
  placeholder,
  marginBottom,
  type,
  text,
  disable = false,
  onChange,
  onChangeValue,
  mLength,
  pattern,
  autocomplete,
  showMessage = false,
  onClick,
}) => {
  const [textValue, setTextValue] = useState(text);

  const onChangeHandle = (event) => {
    setTextValue(event.target.value);
    if (onChange !== undefined && typeof onChange === 'function') {
      onChange(event);
      return;
    }
    if (onChangeValue !== undefined && typeof onChangeValue === 'function') {
      onChangeValue(event.target.value);
      return;
    }
  };

  // useEffect(() => {
  //   if (textValue == null) {
  //     setTextValue(text);
  //   }
  // }, [textValue, text]);

  return (
    <>
      <div className="input-box">
        <label className="label" htmlFor={htmlFor}>
          {label}
        </label>
        {disable ? (
          <input
            className="input"
            type={type || 'text'}
            placeholder={placeholder}
            value={textValue}
            onChange={onChangeHandle}
            disabled
            autoComplete={autocomplete}
            maxLength={mLength}
            pattern={pattern}
            onClick={onClick}
          />
        ) : (
          <input
            className="input"
            type={type || 'text'}
            placeholder={placeholder}
            value={textValue}
            onChange={onChangeHandle}
            maxLength={mLength}
            pattern={pattern}
            autoComplete={autocomplete}
            onClick={onClick}
          />
        )}
        {showMessage ? <div className="message">{message}</div> : undefined}
      </div>

      <style jsx>
        {`
          .input-box {
            margin-bottom: ${marginBottom ? `${marginBottom}px` : '0'};
            .label {
              font-family: Noto Sans TC;
              font-style: normal;
              font-weight: normal;
              font-size: 18px;
              line-height: 26px;
              color: #333333;
              display: block;
              margin-bottom: 8px;
            }

            .input {
              display: block;
              box-sizing: border-box;
              border: 1px solid #00000040;
              border-radius: 3px;
              outline: none;
              padding: 14px;
              width: 100%;
              background-color: #fff;

              &:focus {
                border: 1px solid #5fd2da;
              }
            }

            .message {
              font-family: Noto Sans TC;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 20px;
              color: #5fd2da;
              margin-top: 4px;
            }
          }
        `}
      </style>
    </>
  );
};

export default InputTextField;
