import React from 'react';
import styled from 'styled-components';

import IconSuccess from '../../images/icons/icon-success/icon-success.png';
import Button from '../button';
import DialogBase from './_alert-saleout-base';

const lineHeight = 35;

const AlertTitle = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  color: #3b3516;
  white-space: break-spaces;
  text-align: left;
  margin-bottom: 15px;
`;

const AlertContent = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: ${lineHeight}px;
  max-height: ${lineHeight * 5}px;
  overflow: hidden;
  color: #3b3516;
  text-align: left;
  margin-bottom: 15px;
`;

const AlertButtons = styled.div`
  display: flex;
  column-gap: 12px;
  flex: 0 0 60px;
`;

const SoldOutItem = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AlertSaleOut = ({
  show,
  title,
  saleOutList,
  icon,
  txtLeft,
  txtRight,
  onLeft,
  onRight,
}) => {
  const contentItemMaxRow = window.innerHeight < 750 ? 3 : 4;
  const content = saleOutList.slice(0, contentItemMaxRow).map((item) => {
    return <SoldOutItem>{item}</SoldOutItem>;
  });

  return (
    <>
      <DialogBase show={show} isSoldOutAlert>
        <AlertTitle>{title || ''}</AlertTitle>
        <AlertContent>
          {content}
          {saleOutList.length > 4 && '...'}
        </AlertContent>
        {/* <img className="alert-icon" src={icon || IconSuccess} /> */}
        <AlertButtons>
          {txtLeft !== undefined && txtLeft && (
            <Button gray onClick={onLeft} text={txtLeft || '取消'} />
          )}
          {txtRight !== undefined && txtRight && (
            <Button onClick={onRight} text={txtRight || '確定'} />
          )}
        </AlertButtons>
      </DialogBase>
    </>
  );
};

export default AlertSaleOut;
