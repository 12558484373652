import { createSlice } from '@reduxjs/toolkit';

export const mountData = createSlice({
  name: 'mountData',
  initialState: {
    token: undefined,
    cookie: undefined,
    storeId: undefined,
    accessToken: undefined,
    hashAccount: undefined,
  },

  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setCookie: (state, action) => {
      state.cookie = action.payload;
    },
    setStoreId: (state, action) => {
      state.storeId = action.payload;
    },
    setAccessToken: (state, action) => {
      state.accessToken = action.payload;
    },
    setHashAccount: (state, action) => {
      state.hashAccount = action.payload;
    },
  },
});

export const {
  setToken,
  setCookie,
  setStoreId,
  setAccessToken,
  setHashAccount,
} = mountData.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const isReady = (state) => {
  if (
    state.mountData.token !== undefined &&
    state.mountData.cookie !== undefined &&
    state.mountData.storeId !== undefined &&
    state.mountData.accessToken !== undefined &&
    state.mountData.hashAccount !== undefined &&
    state.userInfo.data.skey !== undefined &&
    state.userInfo.data.customerNo !== undefined
  ) {
    return true;
  }

  return false;
};

export const getToken = (state) => {
  return state.mountData.token;
};
export const getCookie = (state) => {
  return state.mountData.cookie;
};
export const getStoreId = (state) => {
  return state.mountData.storeId;
};
export const getAccessToken = (state) => {
  return state.mountData.accessToken;
};
export const getHashAccount = (state) => {
  return state.mountData.hashAccount;
};

export default mountData.reducer;
