import React from 'react';
import { Provider } from 'react-redux';
import { ProvideAPI } from './src/utils/api';
import { ProvideMount } from './src/utils/mount';
import { ProvideDialog } from './src/utils/dialog';
// import createStore from './src/state/createStore';
import store, { actions, getters } from './src/redux/store';

import 'react-loading-skeleton/dist/skeleton.css';

export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  // const store = createStore();
  // 取得呼叫路徑
  const path = typeof window !== 'undefined' ? window.location.pathname : '';
  const SimplePageList = ['/payment/redirect/'];
  // 如果path在SimplePageList裡面，就不包Provider
  if (SimplePageList.indexOf(path) !== -1) {
    return <ProvideDialog>{element}</ProvideDialog>;
  } else {
    return (
      // <Provider store={store}>
      //   <ProvideAPI>{element}</ProvideAPI>
      // </Provider>
      <Provider store={store}>
        <ProvideAPI>
          <ProvideMount>
            <ProvideDialog>{element}</ProvideDialog>
          </ProvideMount>
        </ProvideAPI>
      </Provider>
    );
  }
};
