import React from 'react';

import Button from '../button';
import DialogBase from './_base';
import InputText from '../input-text';

import { navigate } from 'gatsby';
import { useInput } from 'rooks';

const WalletDialog = ({
  show = false,
  onClick = () => {},
  onClose = () => {},
  price,
  remain,
  showNotOpen = false,
  showText = '',
}) => {
  const codeInput = useInput('');

  const goC4 = () => {
    window.location.assign('carrefourone://?qcOpenWallet');
  };
  return (
    <>
      <DialogBase show={show}>
        <header>家樂福錢包</header>

        <div className="amount-box">
          {remain < price ? (
            <>
              <div className="not-enough-error">
                餘額不足，請至{' '}
                <span
                  className="highlight"
                  onClick={() => {
                    goC4();
                    onClose();
                  }}
                >
                  家樂福APP
                </span>{' '}
                加值或選擇其他付款方式結帳。
              </div>
            </>
          ) : (
            <></>
          )}

          <div className="amount-info">
            <div className="price">
              <div className="title">須支付金額</div>
              <div className="amount">NT$ {price ?? ''}</div>
            </div>
            <div className="remain">
              <div className="title">目前錢包餘額</div>
              {showNotOpen ? (
                <div className="amount not-enough">{showText}</div>
              ) : (
                // <div className={`amount ${remain < price ? 'not-enough' : ''}`}>
                //   {remain ? `NT$ ${remain}` : ''}
                // </div>

                <div className="amount">
                  {remain !== undefined ? `NT$ ${remain}` : ''}
                </div>
              )}
            </div>
          </div>
        </div>

        <InputText
          className="password"
          mLength="6"
          placeholder="請輸入6位數密碼"
          type="password"
          {...codeInput}
          disable={showNotOpen || remain < price}
        />
        <div className="note">
          忘記密碼需到{' '}
          <span
            className="highlight"
            onClick={() => {
              goC4();
              onClose();
            }}
          >
            家樂福APP
          </span>{' '}
          進行設定
        </div>
        <footer>
          <Button onClick={onClose} text="其他方式" gray />
          {!(showNotOpen || remain < price) && (
            <Button
              disabled={remain < price || showNotOpen}
              onClick={() => {
                onClose();
                onClick(codeInput.value);
              }}
              text="確認付款"
            />
          )}
        </footer>
      </DialogBase>

      <style jsx>
        {`
          .highlight {
            font-weight: bold;
            text-decoration: underline;
          }

          header {
            margin-bottom: 29px;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 29px;
            color: #3b3516;
          }

          .amount-box {
            background: #f8f8f8;
            padding: 16px;
            margin-bottom: 32px;

            .not-enough-error {
              background: #f2dede;
              border: 1px solid #ebccd1;
              box-sizing: border-box;
              border-radius: 4px;
              padding: 8px 16px;
              margin-bottom: 16px;
              line-height: 1.5;
              text-align: left;
              color: #a94442;
            }

            .amount-info {
              display: grid;
              align-items: center;
              grid-template-columns: 1fr 1fr;
              column-gap: min(75px, 10vw);

              .title {
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 21px;
                color: #333333;
                margin-bottom: 5px;
              }

              .price {
                .amount {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 21px;
                  color: #333333;
                }
              }

              .remain {
                .amount {
                  font-style: normal;
                  font-weight: normal;
                  font-size: 16px;
                  line-height: 21px;
                  color: #28a745;

                  &.not-enough {
                    color: #fd0202;
                  }
                }
              }
            }
          }

          .password {
            margin-bottom: 8px;
          }

          .note {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 23px;
            color: #3b3516;
            margin-bottom: 32px;
          }

          footer {
            display: flex;
            column-gap: 8px;
          }
        `}
      </style>
    </>
  );
};

export default WalletDialog;
