import { createSlice } from '@reduxjs/toolkit';

export const modelCode = createSlice({
  name: 'modelCode',
  initialState: {
    data: '',
  },

  reducers: {
    setModelCode: (state, action) => {
      state.data = action.payload;
    },
  },
});

export const { setModelCode } = modelCode.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.isLoading`
export const getModelCode = (state) => state.modelCode.data;

export default modelCode.reducer;
