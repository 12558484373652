import React, { useState, useEffect } from 'react';

import Button from '../button';
import DialogBase from './_base';
import InputText from '../input-text';

import IconMailSend from '../../images/icons/i-mail-send.png';
import { useInput } from 'rooks';

const MemberValidationDialog = ({
  show = false,
  onClick = () => {},
  onClose = () => {},
  onClickResend = () => {},
}) => {
  const [showReSend, setShowReSend] = useState(false);
  const codeInput = useInput('');

  const resendTimeout = () => {
    setTimeout(() => {
      setShowReSend(true);
    }, 60 * 5 * 1000);
  };

  const clickResend = () => {
    setShowReSend(false);
    resendTimeout();
    onClickResend();
  };

  useEffect(() => {
    resendTimeout();
  }, []);

  return (
    <>
      <DialogBase show={show}>
        <header>會員驗證</header>

        <div className="information">
          <img
            className="info-icon"
            src={IconMailSend}
            alt="mail sending"
            width={104}
          />
          <div className="info-text">請輸入簡訊傳送的驗證碼，謝謝。</div>
        </div>

        <div className="password">
          <InputText
            placeholder="請輸入驗證碼"
            mLength="6"
            type="text"
            autocomplete="one-time-code"
            {...codeInput}
          />

          <div
            className="resend-button"
            hidden={!showReSend}
            onClick={() => {
              clickResend();
            }}
          >
            重新發送
          </div>
        </div>

        <footer>
          <Button onClick={onClose} text="取消" gray />
          <Button
            onClick={() => {
              onClose();
              onClick(codeInput.value);
            }}
            text="確認付款"
          />
        </footer>
      </DialogBase>

      <style jsx>
        {`
          header {
            margin-bottom: 29px;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 29px;
            color: #3b3516;
          }

          .information {
            background: #f8f8f8;
            padding: 16px;
            margin-bottom: 32px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: min(10px, 5vw);
            align-items: center;

            .info-text {
              width: 136px;
              text-align: left;
            }
          }

          .password {
            position: relative;
            margin-bottom: 32px;

            .resend-button {
              position: absolute;
              top: 10px;
              right: 10px;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 23px;
              text-align: right;
              color: #5fd2da;
            }
          }

          footer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            column-gap: 8px;
          }
        `}
      </style>
    </>
  );
};

export default MemberValidationDialog;
